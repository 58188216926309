// import { navigate } from "gatsby"
import React from "react"
// import { Helmet } from "react-helmet"
// import { useForm } from "react-hook-form"
// import AuthenticationContext from "../context/authentication/AuthenticationContext"
// import StateContext from "../context/state/StateContext"
// import { getUser, makeLogOut } from "../helpers/DrupalHelper"
import LayoutMinimal from "../components/Layout/Minimal"
// import Button from "../components/Elements/Button"
// import FloatingInput from "../components/Elements/Form/FloatingInput"
// import NotificationContext from "../context/Notification/NotificationContext"
// import { useLocation } from "@reach/router"

const Failed = () => {

    return (
        <LayoutMinimal
            title={"Login Failed."}
            footer={"©2025 O2O. All rights reserved."}
            metaTitle={"Failed"}
        >
            <p className="text-reg-16 text-center mb-[20px]">
                Microsoft Azure login failed. Please try again by visiting our login page at <a href="/login" className="text-blue-500 underline">Login</a>.
            </p>

            <div className="flex justify-center items-center mt-6">

            </div>
        </LayoutMinimal>
    )
}

export default Failed
